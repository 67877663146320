import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import {
  faChargingStation,
  faExclamationCircle,
  faCircle,
} from '@fortawesome/free-solid-svg-icons';
import DirectionsIcon from '@mui/icons-material/Directions';
import LaunchIcon from '@mui/icons-material/Launch';
import { getMapLink } from './MapLink';
import { Box, Chip, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import '../App.css';
import { useCustomTheme } from './CustomThemeContext';

const lightMapStyles = [
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.attraction',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.government',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
];

const darkMapStyles = [
  { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
  { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
  { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#263c3f' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#6b9a76' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#38414e' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#212a37' }],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9ca5b3' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ color: '#746855' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#1f2835' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#f3d19c' }],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [{ color: '#2f3948' }],
  },
  {
    featureType: 'transit.station',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#17263c' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#515c6d' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#17263c' }],
  },
];

function createCustomIcon(color, badge, icon, badgecolor) {
  let svg;
  if (badge) {
    const iconSvg = icon.icon[4]; //  Icon-Array enthält den svg-Path an 5. Stelle
    svg = `
          <svg viewBox="0 -10 48 48" xmlns="http://www.w3.org/2000/svg">
              <path fill="${color}" d="M14,2 C21.732,2 26,7.641 26,13.6 C26,24.063 14,36 14,36 C14,36 2,24.063 2,13.6 C2,7.641 6.268,2 14,2 Z" stroke="white" stroke-width="1"></path>
              <path fill="${badgecolor}" d="${iconSvg}" transform="translate(20, -5) scale(0.024)" stroke="white" stroke-width="25" />
          </svg>
      `;
  } else {
    svg = `
          <svg viewBox="0 -10 48 48" xmlns="http://www.w3.org/2000/svg">
              <path fill="${color}" d="M14,2 C21.732,2 26,7.641 26,13.6 C26,24.063 14,36 14,36 C14,36 2,24.063 2,13.6 C2,7.641 6.268,2 14,2 Z" stroke="white" stroke-width="1"></path>
          </svg>
      `;
  }
  return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`;
}

const Map = () => {
  const { themeMode } = useCustomTheme();
  // const [theme] = useState(() => localStorage.getItem('theme') || 'light');
  const [currentLocation, setCurrentLocation] = useState(null);
  const [data, setData] = useState([]);
  const [selectedElement, setSelectedElement] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [showInfoWindow] = useState(true);
  const [isTabActive, setIsTabActive] = useState(true);

  useEffect(() => {
    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        setCurrentLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (error) => {
        console.error('Error getting current location:', error);
      },
      { enableHighAccuracy: true },
    );

    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, []);

  const center = useMemo(
    () => ({ lat: 49.474712595254196, lng: 10.988897377528858 }),
    [],
  );

  const blueDotIcon = useMemo(
    () => ({
      path: faCircle.icon[4],
      fillColor: `#4285F4`,
      fillOpacity: 1,
      strokeWeight: 1.5,
      strokeColor: '#ffffff',
      scale: 0.025,
    }),
    [],
  );

  const fetchData = useCallback(() => {
    fetch('https://parken-fuerth.de/PH', {
      headers: {
        Auth: 'Bearer CJhbGciOiJIUzUxMiJ9.eyJ1c2VyIjoiYWJvQGluZnJhLWZ1ZXJ0aC5kZSIsImNvZGUiOiJyWVZWbllYelVtbnA4UkR5UGxLTE1qc29kSk91anQ0c200cFBWODRsV0g0PSJ9.5O2aQQciD4NTPu3nPRv4ezFupYF8IKJDV8h8MLZ',
      },
    })
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(!document.hidden);
    };

    fetchData(); // Fetch data immediately when the component mounts

    const interval = setInterval(() => {
      if (isTabActive) {
        fetchData(); // Fetch data every minute if the tab is active
      }
    }, 60000);

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearInterval(interval);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [fetchData, isTabActive]);

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`); //full window enabled on every device at mount

    window.addEventListener('resize', () => {
      //full window enabled at resizing
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    return () => {
      window.removeEventListener('resize', () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      });
    };
  }, []);

  const mapOptions = useMemo(
    () => ({
      styles: themeMode === 'dark' ? darkMapStyles : lightMapStyles,
    }),
    [themeMode],
  );

  return (
    <GoogleMap
      // id="dark-map"
      id="map"
      zoom={15}
      center={center}
      mapContainerClassName="map-container"
      options={mapOptions}
    >
      {currentLocation && (
        <Marker position={currentLocation} icon={blueDotIcon} />
      )}

      {data.map((element, index) => {
        let iconUrl;
        if (element.stand === 'Daten nicht aktuell') {
          iconUrl = createCustomIcon(
            element.color.toString(),
            true,
            faExclamationCircle,
            '#F25C56',
          );
        } else if (element.eplaetze && element.eplaetze > 0) {
          iconUrl = createCustomIcon(
            element.color.toString(),
            true,
            faChargingStation,
            element.ecolor.toString(),
          );
        } else {
          iconUrl = createCustomIcon(element.color.toString(), false);
        }

        return (
          <Marker
            key={index}
            title={element.bezeichnung}
            position={{
              lat: element.lat,
              lng: element.lng,
            }}
            label={{
              text: element.plaetze.toString(),
              fontFamily: '',
              color: '#ffffff',
              fontSize: '18px',
            }}
            icon={{
              url: iconUrl,
              scaledSize: new window.google.maps.Size(70, 70),
              anchor: new window.google.maps.Point(20, 70),
              labelOrigin: new window.google.maps.Point(20, 35),
            }}
            onClick={(event, marker) => {
              setSelectedElement(element);
              setActiveMarker(marker);
            }}
          />
        );
      })}

      {selectedElement && (
        <InfoWindow
          options={{
            pixelOffset: new window.google.maps.Size(0, -45),
          }}
          position={{
            lat: selectedElement.lat,
            lng: selectedElement.lng,
          }}
          visible={showInfoWindow}
          marker={activeMarker}
          onCloseClick={() => {
            setSelectedElement(null);
          }}
        >
          <div id="dark-mode">
            <Stack direction="row" spacing={1}>
              <Chip
                label={selectedElement.art}
                size="small"
                sx={{
                  backgroundColor: '#ADD8E6',
                  color: '#000',
                  fontSize: '12px',
                }}
              />
            </Stack>

            <Typography
              sx={{ fontSize: '16px', fontWeight: 500, lineHeight: '2' }}
              component="div"
              variant="title1"
            >
              {selectedElement.bezeichnung_kurz}
            </Typography>

            <Typography
              id="dark-mode-font"
              sx={{ fontSize: '14px', lineHeight: '1.3' }}
              variant="subtitle1"
              color="text.secondary"
              component="div"
              paddingBottom="10px"
            >
              {selectedElement.strasse}
              <br />
              {selectedElement.ort}
            </Typography>

            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={1}
              sx={{
                flexWrap: 'wrap',
              }}
            >
              {selectedElement.eplaetze && (
                <Chip
                  id="dark-mode-bg"
                  label={`Freie Ladeplätze: ${selectedElement.eplaetze}`}
                  size="small"
                />
              )}
              {selectedElement.bplaetze && (
                <Chip
                  id="dark-mode-bg"
                  label={`Freie Behindertenparkplätze: ${selectedElement.bplaetze}`}
                  size="small"
                />
              )}
              {selectedElement.max_hoehe && (
                <Chip
                  id="dark-mode-bg"
                  label={`Einfahrtshöhe: ${selectedElement.max_hoehe} m`}
                  size="small"
                />
              )}
              <Chip
                id="dark-mode-bg"
                label={`Betreiber: ${selectedElement.bez_int}`}
                size="small"
              />
              <Chip
                id="dark-mode-bg"
                label={`${selectedElement.stand} (${selectedElement.zeit_kurz})`}
                size="small"
              />
            </Stack>

            <Box sx={{ marginTop: '20px' }}>
              {' '}
              {/* Add more space above the bottom chips */}
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                <Chip
                  id="dark-mode"
                  label="Betreiberwebsite"
                  variant="outlined"
                  component="a"
                  href={selectedElement.website}
                  target="_blank"
                  icon={<LaunchIcon />}
                  sx={{ flex: '1 0 100%', maxWidth: { xs: '100%', sm: '45%' } }}
                />
                <Chip
                  id="dark-mode"
                  label="Öffnen in Maps"
                  variant="outlined"
                  component="a"
                  href={getMapLink(selectedElement)}
                  icon={<DirectionsIcon />}
                  sx={{ flex: '1 0 100%', maxWidth: { xs: '100%', sm: '45%' } }}
                />
              </Stack>
            </Box>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

export default Map;
