import Typography from '@mui/material/Typography';
import React, { useState, useEffect } from 'react';

const FetchTime = ({ data }) => {
  const [elapsedSeconds, setElapsedSeconds] = useState(0);

  useEffect(() => {
    if (data) {
      const intervalId = setInterval(() => {
        setElapsedSeconds(Math.floor((Date.now() - new Date(data)) / 1000));
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [data]);

  return (
    <>
      <Typography sx={{ textAlign: 'right', fontSize: '9.6px' }}>
        {/* Daten aktualisiert */}
      </Typography>
      <Typography
        id="header"
        sx={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'flex-end',
          fontSize: '13px',
          color: 'text.secondary',
          textAlign: 'right',
        }}
      >
        Parkraumdaten vor
        <br />
        {elapsedSeconds} Sek. aktualisiert
      </Typography>
    </>
  );
};

export default FetchTime;
