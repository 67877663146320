import Typography from '@mui/material/Typography';
import React from 'react';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import DarkMode from './DarkMode';
import Container from '@mui/material/Container';

const Footer = () => (
  <Container
    id="footer"
    sx={{
      bgcolor: '#f1f3f4',
      paddingTop: '20px',
      paddingBottom: '75px',
    }}
    component="footer"
    maxWidth="md"
  >
    <Typography variant="h6" align="center" gutterBottom></Typography>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box></Box>
      <Box sx={{ paddingLeft: '60px' }}>
        <Link
          color="inherit"
          href="https://www.infra-fuerth.de/privatkunden/meta/impressum"
        >
          Impressum
        </Link>
        {' | '}
        <Link color="inherit" href="https://www.infra-fuerth.de/datenschutz">
          Datenschutz
        </Link>
      </Box>
      <Box>
        <DarkMode />
      </Box>
    </Box>
  </Container>
);

export default Footer;
