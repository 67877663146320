import React, { useEffect, useState } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import Card from '@mui/material/Card';
import { Box, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import Map from './Map';

const MapCookie = () => {
  const [showMap, setShowMap] = useState(false);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAoZ8gmzgwE7ncMPWM5nfET-QuisVPQAFc',
  });

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsentMap');
    setShowMap(consent === 'true');
  }, []);

  const handleConsent = () => {
    localStorage.setItem('cookieConsentMap', 'true');
    setShowMap(true);
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div>
      {!showMap ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '160px 15px 0 15px',
          }}
        >
          <Card
            id="dark-mode"
            elevation={4}
            sx={{
              display: 'flex',
              borderRadius: '12px',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Box sx={{ padding: '15px 15px 30px 15px', textAlign: 'center' }}>
              <Typography sx={{ paddingBottom: '20px' }}>
                Für die Kartenansicht verwenden wir Google Maps. Wenn du den
                Dienst aktivierst, stimmst du der Datenweitergabe an Google und
                deren Verarbeitung zu.
              </Typography>
              <Button
                sx={{
                  color: 'black',
                  backgroundColor: 'white',
                  borderColor: 'black',
                  '&:hover': {
                    color: 'black',
                    backgroundColor: 'white',
                    borderColor: 'black',
                  },
                }}
                variant="outlined"
                onClick={handleConsent}
              >
                Karte anzeigen
              </Button>
            </Box>
          </Card>
        </div>
      ) : (
        <Map />
      )}
    </div>
  );
};

export default MapCookie;
