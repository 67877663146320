import React, { useCallback, useEffect, useState } from 'react';
import ParkCardItem from './ParkCardItem';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useRecoilState } from 'recoil';
import { parkraumAtom } from '../recoil/atom';
import FetchTime from './FetchTime';

const ParkCards = () => {
  const [parkraum, setParkraum] = useRecoilState(parkraumAtom);
  const [fetchTime, setFetchTime] = useState(null);
  const [isTabActive, setIsTabActive] = useState(true);

  const fetchData = useCallback(() => {
    fetch('https://parken-fuerth.de/PH', {
      headers: {
        Auth: 'Bearer CJhbGciOiJIUzUxMiJ9.eyJ1c2VyIjoiYWJvQGluZnJhLWZ1ZXJ0aC5kZSIsImNvZGUiOiJyWVZWbllYelVtbnA4UkR5UGxLTE1qc29kSk91anQ0c200cFBWODRsV0g0PSJ9.5O2aQQciD4NTPu3nPRv4ezFupYF8IKJDV8h8MLZ',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        data.forEach((obj) => {
          obj.favorit = false;
        });
        setParkraum(data);
        setFetchTime(Date.now());
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [setParkraum]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(!document.hidden);
    };

    fetchData(); // Fetch data immediately when the component mounts

    const interval = setInterval(() => {
      if (isTabActive) {
        fetchData(); // Fetch data every minute if the tab is active
      }
    }, 60000);

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearInterval(interval);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [fetchData, isTabActive]);

  return (
    <Container
      sx={{
        py: 0,
        /*bgcolor: '#eaeaea'*/
      }}
      maxWidth="md"
    >
      <FetchTime data={fetchTime}></FetchTime>
      <Grid container spacing={2.5} sx={{ paddingTop: '10px' }}>
        {parkraum.length > 0 &&
          parkraum.map((x, id) => {
            return (
              x.favorit && <ParkCardItem key={id} parkraum={x}></ParkCardItem>
            );
          })}
        {parkraum.length > 0 &&
          parkraum.map((x, id) => {
            return (
              !x.favorit && <ParkCardItem key={id} parkraum={x}></ParkCardItem>
            );
          })}
      </Grid>
    </Container>
  );
};

export default ParkCards;
