import React from 'react';
import Hero from './Hero';
import ParkCards from './ParkCards';
import Footer from './Footer';

const Home = () => (
  <>
    <Hero />
    <ParkCards />
    <Footer />
  </>
);

export default Home;
