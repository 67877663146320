import React, { useState } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import ListIcon from '@mui/icons-material/List';
import MapIcon from '@mui/icons-material/Map';
import Home from './Home';
import MapCookie from './MapCookie';

const BottomNavigationApp = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div
      class="safe-bottom"
      className={isIOS() ? 'ios-bottom-padding' : '50px'}
    >
      {value === 0 && <Home />}
      {value === 1 && <MapCookie />}
      <BottomNavigation
        id="dark-mode"
        value={value}
        onChange={handleChange}
        showLabels={true}
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          bgcolor: '#fff',
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
          boxShadow: '0 -1px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        <BottomNavigationAction
          id="dark-mode"
          label="Übersicht"
          icon={<ListIcon />}
        />
        <BottomNavigationAction
          id="dark-mode"
          label="Karte"
          icon={<MapIcon />}
        />
      </BottomNavigation>
    </div>
  );
};

// const isIOS = () => {
//     const userAgent = window.navigator.userAgent.toLowerCase();
//     return /iphone|ipad|ipod/.test(userAgent);
// };
// const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

const isIOS = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const isIPhoneXOrNewer =
    /iphone/.test(userAgent) && window.innerHeight >= 812; // iPhone X and above
  return isIPhoneXOrNewer;
};
export default BottomNavigationApp;
