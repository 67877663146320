import React from 'react';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

const Hero = () => (
  <Box
    id="top-bar"
    sx={{
      bgcolor: '#f1f3f4',
      pt: 8,
      pb: 6,
      paddingTop: 2,
      paddingBottom: 0,
    }}
  >
    <Container id="top-bar" maxWidth="sm">
      {/*<Typography*/}
      {/*  component="h3"*/}
      {/*  variant="h4"*/}
      {/*  align="center"*/}
      {/*  color="text.primary"*/}
      {/*  gutterBottom*/}
      {/*>*/}
      {/*  Fürther Parkraumübersicht*/}
      {/*</Typography>*/}
      {/*<Typography variant="h7" align="center" color="text.secondary" paragraph>*/}
      {/*  Mit uns finden Sie die besten freien Parkmöglichkeiten in Fürth.*/}
      {/*</Typography>*/}
      <Stack
        sx={{ pt: 0, marginBottom: '-36px' }}
        direction="row"
        spacing={2}
        justifyContent="left"
      >
        <Box
          // component="img"
          // src="https://placehold.co/50"
          alt="Logo"
          sx={{
            width: 50,
            height: 50,
          }}
        />
      </Stack>
    </Container>
  </Box>
);

export default Hero;
