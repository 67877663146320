import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

const tempFahrenheitAtom = atom({
  key: 'tempFahrenheit',
  default: 32,
  effects: [persistAtom],
});

const parkraumAtom = atom({
  key: 'parkraum',
  default: [],
  effects: [persistAtom],
});

export { tempFahrenheitAtom, parkraumAtom };
